import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#7DCEA0'
    }}>
  <h1 style={{
        color: 'Blue'
      }}>PROJECTS</h1>
    </div>
    <hr />
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#E9F7EF'
    }}>
As the founder, director, senior consultant and primary software developer of '<b>INTELLECT Computer Systems 
Inc.</b>', an Information Technology Company founded in 1993, incorporated in Ontario and dealing with 
Information Technology Consulting, Analysis and Software Development, completed successfully the following 
projects:
  <br />
  <br />
  <h2 style={{
        color: 'Green'
      }}>INVESTMENT TECHNOLOGY GROUP - IT Consulting &amp; Software Development</h2>
  <hr />
  <br />
    In addition to a broad range of software engineering skills, the knowledge of the Canadian Market Microstructure has been utilized to enhance the Firm's next-generation trading platform and to solve a multitude of highly technical and complex problems on multiple projects including the following:
    <br /><br />
    <ul>
        <li>Real-Time Data-Feeds Projects: Custom Feeds Retrieval [ILX Data], Multi-Currency FX Feeds [Reuters], Public &amp; Private Trades (Consolidated Last Sale [CLS]) and Quotes (Canadian Best Bid and Offer [CBBO]) from TMX for all Exchanges :: Equities Trading Team &amp; Core Research Team</li>
        <li>Real-Time Currency Exposure &amp; Foreign Exchange Projects :: Equities Trading Team &amp; Core Infrastructure &amp; Routing Team</li>
        <li>Real-Time Institutional Trading Strategies :: Equities Trading Team &amp; Core Research Team</li>
        <li>Real-Time Algorithmic &amp; Ultra-Low Latency Trading Projects :: Core Infrastructure &amp; Routing Team</li> 
        <li>High-Performance Order Routing &amp; Trading Engine Projects :: Core Infrastructure &amp; Routing Team</li> 
        <li>Canadian Market-Data API Projects (Data Retrieval &amp; Database Projects) :: Equities Trading Team &amp; Core Research Team</li>
        <li>STAMP &amp; FIX Protocol Products Development :: Equities Trading Team &amp; Core Research Team</li>
        <li>Various Post-Trade Projects :: All Departments</li>
        <li>Desktop Development Projects :: All Departments</li>
        <li>Automated QA Testing Evaluation Projects :: QA Department</li>
        <li>Miscellaneous Client/Server, Database, Shell Scripting &amp; Web Development (With Auditing) Projects :: All Departments</li>
        <li>Docker Evaluation &amp; Joomla Documentation Project</li>
        <li>Real-Time Trading Applications Monitoring</li>
        <li>Executions Report Card &amp; Destinations Reporting Projects (Command-Line &amp; Web, Daily, Historical, Custom-Query) :: Equities Trading Team &amp; Core Research Team</li>
        <li>Best Executions Reporting Projects :: Various Clients</li>
        <li>Reporting and Data Reconciliation Tools (Including Enhanced Crystal Reports Web Projects)</li>
        <li>Order Management Systems Projects</li>
        <li>User Interfaces Projects</li>
        <li>Scripting &amp; Utility Development Projects</li>
        <li>Business Continuity (BCP) / Disaster Recovery (DR) Planning Projects</li> 
        <li>Ad-hoc Quantitative Analysis Projects :: For Product Management</li>
        <li>Various Start-Up Software Analysis &amp; Development Projects for the Post-Trade, Core Research, Algorithmic Trading Development, Finance, Compliance, Mid-Office, Sales &amp; Trading and Core Infrastructure &amp; Routing Teams, the TriAct Canada Marketplace (TCM, for the MATCHNow® Canadian Alternative Trading System [ATS]) and the Investment Industry Regulatory Organization of Canada (IIROC)</li>
  </ul>
  <hr />
  <pre>{"\n"}</pre>
  <h2 style={{
        color: 'Green'
      }}>UBS BANK CANADA - Technical Writing, Modeling (Software Systems Architecture)</h2>
  <hr />
  <br />
  Technical Writing Projects (Systems Architecture Documentation &amp; UML Modelling):
    <br /><br />
    <ul>
      <li> International Client Reporting Project ('ICR I' &amp; 'ICR 2000')</li>
      <li> SmartSizing Project (Overview &amp; Real-Time)</li>
  </ul>
  <hr />
  <pre>{"\n"}</pre>
      <h2 style={{
        color: 'Green'
      }}>TORONTO DOMINION SECURITIES INC.</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "right"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><b><u>{`PROJECT:`}</u></b></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`‘`}<b>{`LED`}</b>{`’ (Listed Equities `}{`&`}{` Derivatives)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><b><u>{`DEPARTMENT:`}</u></b></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`Institutional Equities `}{`&`}{` Structured Finance`}</b></td>
          </tr>
        </tbody>
      </table>
      <br />
Performed analysis and interfacing with the Listed Options traders of the Toronto Dominion Securities, 
to analyze the business needs and improve the trading environment and the support of their business 
activities.
      <p>{`Developed a custom-made, open-architecture, multi-currency, centralized, scalable and efficient
application to deal with the data capture and reporting of the Corporation trading community, which
covers all traded instruments (Stocks, Stock Options, Indices, Index Options, Futures, Future Options,
Index Futures, Index-Future Options and FRAs).`}</p>
      <p>{`The application was developed in `}<b>{`Microsoft Visual Basic v5.0 (SP3)`}</b>{` and is supported by an `}<b>{`ODBC-compliant`}</b>{`
back-end database (prototyped in `}<b>{`Microsoft Access 97`}</b>{` and up-scaled to a `}<b>{`Microsoft SQL Server`}</b>{` database, residing
on the network). Among others, it handles the following processes:`}</p>
      <ul>
  <li> <b>Trade Capture</b> (via the Application GUI directly or by means of automated population from Excel spreadsheet files).</li>
  <li> <b>Option Expiry</b> and Option Exercise/Assignment (fully or partially, to deal with real-life situations and to simplify operations, as the trades/records that result in a net position may be numerous).</li>
  <li> <b>Daily Data Transfer</b> to the Risk Management Group’s <b>FTP</b> servers (Retail-Option positions for all traded underlying securities and options).</li>
  <li> <b>Reporting</b> (Daily Trades, Daily Positions, Net Positions, Profit &amp; Loss).</li>
  <li> <b>Market Data Retrieval</b> (automated and ad-hoc) from Reuters data sources.</li>
  <li> <b>Implied Volatilities Modelling</b>.</li>
  <li> <b>Options Valuation</b>.</li>
      </ul>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>TORONTO DOMINION BANK</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "right"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><b><u>{`PROJECT:`}</u></b></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`Risk Management Systems`}</b></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><b><u>{`DEPARTMENT:`}</u></b></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`Research `}{`&`}{` Development Division`}</b></td>
          </tr>
        </tbody>
      </table>
      <br />
Played an active role during the Bank’s Risk Management Systems Project, the objective of which was 
to implement a new system that would be used for the daily measurement and management of market risk 
for the Bank’s global trading books, supported by approximately 20 source systems. The result of these 
market risk calculations (the global <b>Value-at-Risk [VaR]</b> figure) was reported directly to Bank executives 
as well as to regulatory authorities, in order to comply with a set of regulatory requirements.
      <br />
During my involvement with the project, in a Software Development/Consulting capacity, I had the privilege 
to develop from scratch, test, document and maintain:
      <br /><br />
      <ul>
    <li>One third of the total number of the required <b>pre-processors</b> and related software tools for the 
        project (translating data from legacy mainframe systems to the new <b>Risk-Management System</b>):</li>
      <ul>
        <li><b>MIS</b><sup>1</sup> &amp; Supporting Applications (including the Special Features Handling)</li>
        <li><b>FIS</b><sup>2</sup> &amp; Supporting Applications (including the Special Features Handling)</li>
        <li><b>OASIS</b><sup>3</sup></li>
        <li><b>GLOBAL</b><sup>4</sup></li>
        <li><b>DEALS</b><sup>5</sup></li>
        <li><b>PRIORITY</b></li>
        <li><b>Zar/PCA</b></li>
        <li><b>DIFF</b>, <b>CrTable</b>, <b>Bloomberg FTP-Connectivity</b>, <b>Graphical User Interfaces</b> and other software tools.</li>
      </ul>
    The main development effort utilized <b>Perl</b> language and scripting techniques under the <b>Sun Solaris UNIX</b> 
    environment [<b>Perl/Tk</b>, <b>SYBASE</b>, <b>SQL-SERVER</b>, <b>‘MAKEFILEs’</b>, <b>‘rcs’</b>, <b>‘pod’</b> and <b>‘man’</b>, <b>MS-Word</b> documentation].
      </ul>
      <ul>
    <li>A rich database-promotion application, code-named ‘<b>DBupd</b>’ (developed with the <b>Enterprise 
        Edition of Visual Basic v5</b>, under <b>Windows NT</b>) for the maintenance of the required tables of the 
        new <b>Risk Management Systems</b> [<b>Visual Basic</b>, <b>SYBASE</b> on <b>UNIX</b>, <b>SQL SERVER</b> on <b>Windows NT</b>, 
        <b>Report Writers</b>, <b>ODBC registry scripts</b>, <b>Windows Help Compilers</b> and <b>Application Setup Utilities</b>].</li>
    <li>A large number of utility programs (designed and coded in <b>Perl/Tk</b>, <b>C</b> and <b>Visual Basic</b>) and <b>UNIX 
        shell scripts</b> prepared for a <b>Windows NT</b> / <b>Sun Solaris</b> platform.</li>
      </ul>
In addition, I dealt with other peripheral projects in the Bank’s Research &amp; Development Division 
('<b>Open Bloomberg Data Licensing &amp; History Information Retrieval</b>' and automated data file tranfers) in a consulting and 
software development role.
      <hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}></th>
            <th parentName="tr" {...{
              "align": "left"
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><sup>{`1`}</sup></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`The MIS system captures transactions on Treasury Bills, Interest Rate Futures, Bond Futures, Convertibles, Floating Rate Notes (FRNs), Zero Coupons and Vanilla Bonds.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><sup>{`2`}</sup></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`The FIS system supports the Toronto Dominion Bank’s fixed income trading (including Bonds, Coupons, Strip Coupons and Mortgage Backed Securities).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><sup>{`3`}</sup></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`The OASIS system supports the Toronto Dominion Bank’s Money Market (T-Bills, Deposits `}{`&`}{` Loans, Bankers Acceptance, Bonds) and Foreign Exchange (FX Swaps, FX Forwards, FX Spots) products, traded offshore (Singapore, Taipei, Hong Kong, Tokyo).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><sup>{`4`}</sup></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`The GLOBAL system supports the Deposits, Loans, and Memos and allows the maintenance of other financial products including Commercial Paper, Bankers Acceptance, CDs, Interest Rate Swaps, FRAs, T-Bonds, Call Deposits `}{`&`}{` Loans.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><sup>{`5`}</sup></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`The DEALS system supports the Toronto Dominion Bank’s Foreign Exchange products (FX Swaps, FX Forwards, FX Spots), traded in Toronto.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      {
        /* <Link to="director#Top">≡ Top</Link> */
      }
      <a href="#Top" style={{
        color: 'rgb(255,255,0)'
      }}><font color="#E67E22">≡ Top</font></a>
    </div>
    <br />
    <br />
    <br />
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#7DCEA0'
    }}>
  <h1 style={{
        color: 'Blue'
      }}>OLDER PROJECTS, BY PROGRAMMING LANGUAGE &amp; ENVIRONMENT</h1>
    </div>
    <hr />
    <div style={{
      padding: '30px 60px',
      backgroundColor: '#E9F7EF'
    }}>
      <h2 style={{
        color: 'Green'
      }}>SPEEDWARE 4GL/SQL, DESIGNER, ALLBASE</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Analysis, Programming, testing, debugging, enhancements and optimization work on the `}<b>{`\`SPEEDWARE FINANCIALS'`}</b>{` `}<b>{`A/R`}</b>{`, `}<b>{`A/P`}</b>{` `}{`&`}{` `}<b>{`G/L`}</b>{` packages. (`}<b>{`SPEEDWARE CORPORATION`}</b>{`).`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>INFORMIX 4GL/SQL, C</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Programming, testing, debugging, optimization, maintenance and administration of the `}<b>{`'SALES'`}</b>{` `}{`&`}{` `}<b>{`\`TIMESHEET'`}</b>{` database systems. (`}<b>{`ROBBINS `}{`&`}{` MYERS CANADA LTD.`}</b>{`).`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>SCULPTOR 4GL/SQL, Turbo C, ASSEMBLY</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`APPL`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Applicants' details registering database system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ASCIGRAF`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Large `}<b>{`ASCII`}</b>{` character production utility (3 sizes).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ASCII`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Control character stripping utility, for any input file specified.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ASSETS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed assets register: maintenance, debugging, patching, improvements.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`BIDS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tendering details maintenance system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`CASE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`Computer-Aided System for Estimators`}</b>{`: original programming `}{`&`}{` patching work, debugging, testing, maintenance.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`CSV`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Comma-Separated-Values format utility. Produces CSV output files from any given `}<b>{`SCULPTOR`}</b>{` database files (Tables).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`DW`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Day-Works database management system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`FDDCAT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Automatic reading of floppy diskette directory tables and saving to a database file.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`FORM`}{`*`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Intelligent output of spreadsheet formulas to `}<b>{`ASCII`}</b>{` Files (from `}<b>{`SuperCalc`}</b>{`).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`GRESLEX!`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Greek / Esperanto dictionary system: Development, implementation.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`J`}{`&`}{`PMENU`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Complete `}<b>{`DOS`}</b>{` menuing system, with password-protected features.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`MAPLE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Materials procurement system: original programming, patching work, maintenance, improvements.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`METRIC`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Elementary simulation of spreadsheet techniques (simple calculations combined with database functions).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`NL`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Multi-currency accounting system: patching work, testing, debugging, amendments, additions, maintenance, optimization.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`NLGRK`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Greek nominal ledger system (adaptation / conversion / testing).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ORDERS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Materials ordering database system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ORGCHART`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Organogram-making utility (redirection to file).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`PAGING`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Production of numbered forms for stamping by the tax service (Greece).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`QUERY`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Easy interactive SQL interface system `}{`&`}{` report generator (Global Query!).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`SOFTFONT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Software font creator (`}<b>{`.COM`}</b>{` files) for `}<b>{`EGA`}</b>{`/`}<b>{`VGA`}</b>{` display systems (`}<b>{`font editor`}</b>{`).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`SORT!!!`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Original work for the lexicographically correct sorting of small / capital / accented letters in a Greek dictionary system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`SUPPLIER`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Suppliers registering database system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`VDUFILE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`VDU`}</b>{` file manipulator for screen attributes customization of `}<b>{`SCULPTOR`}</b>{` applications.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>SuperCalc IV/V</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`AGIS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Another price tabulation system: comparative unit costs.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`CASH`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Stamp duty calculation system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ONCOST`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Total project cost estimating system: macro programming.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`PRTAB`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`PRice TABulation system for engineers.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`TIME3`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Macro system to span time period data.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>Turbo C v2.0</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`DIREDIT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`DOS`}</b>{` directory listing output manipulation.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`FILEDIT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`File editing utility.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`SCREDIT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Captured screen file editing utility for documentation purposes.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`WINBU!`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Interactive `}<b>{`DOS`}</b>{` backup utility.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Isolation of international character sequences in word-processed documents, for indexing purposes, etc.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Document conversion utilities for typesetting, screen modes programming, dynamic memory allocation techniques.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>DBASE, R&amp;R, PROKEY</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ARTIST`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dakis Joannou's art collection maintenance database system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`DEMO{5%}`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tax affairs maintenance system (Greece).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`EST`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Companies register / directory.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`JPORDER`}{`*`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Ordering system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`LS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Greek Accounting system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`PERLEAVE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Personnel / leave registering system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`PROKEY`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Macros design for `}<b>{`R`}{`&`}{`R`}</b>{` `}<b>{`ASCII`}</b>{` file input.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`QCODE+`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Customization / optimization of `}<b>{`QCODE+`}</b>{` applications generator.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`TAX`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tax / customs affairs maintenance.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Original `}<b>{`dBASE`}</b>{` and `}<b>{`R`}{`&`}{`R`}</b>{` report design for all `}<b>{`dBASE`}</b>{` applications.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>FoxPro v2.0, v2.5</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Downloading from `}<b>{`XENIX`}</b>{` / `}<b>{`INFORMIX`}</b>{` of selected parts of a huge `}<b>{`'SALES'`}</b>{` database and implementation of the same system in a `}<b>{`NOVELL`}</b>{` / `}<b>{`FoxPro`}</b>{` environment.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>Quick Basic v4.5</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`[Various Projects]`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Development work for the production of custom optimization tables for efficient mixer selection procedures (Fluids Handling Industry).`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>NUTSHELL</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`ORGANIZE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Computer books `}{`&`}{` software inventory monitoring system.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`PHONE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Telephone directory `}{`&`}{` address book.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`TAX`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tax contributions maintenance system.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>PROCOMM+</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`PRSCRIPT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`PROCOMM+`}</b>{` script language programming for `}<b>{`MODEM`}</b>{` work.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>PRESCRIBE / WP / DTP</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`BONDS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><b>{`DTP`}</b>{` Application: Bonds printing, in a laser printer environment.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`FAXFORMS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Design `}{`&`}{` production of `}<b>{`FAX`}</b>{` templates for use with laser printer facilities.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`QAMANUAL`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Design of `}<b>{`laser printer`}</b>{` forms for the production of quality assurance manuals.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>DOS, NOVELL, XENIX, UNIX, WINDOWS 3.11, WINDOWS 95 &amp; BANYAN VINES Environments</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "right"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Software `}{`&`}{` hardware configuration / optimization.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Batch / script file programming under `}<b>{`DOS`}</b>{`, `}<b>{`XENIX`}</b>{` `}{`&`}{` `}<b>{`UNIX`}</b>{` environments.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Expanded / extended memory management testing and troubleshooting.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Installation, setup `}{`&`}{` troubleshooting of `}<b>{`DOS`}</b>{` applications.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Design `}{`&`}{` modification of printer drivers for `}<b>{`WordPerfect 5.1`}</b>{` `}{`&`}{` `}<b>{`SCULPTOR 4GL/SQL`}</b>{` applications.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Project management / Time Analysis work (`}<b>{`MS PROJECT`}</b>{`, `}<b>{`OPEN PLAN`}</b>{`, `}<b>{`MICRO PROJECT`}</b>{` `}{`&`}{` `}<b>{`SUPER PROJECT EXPERT`}</b>{`).`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Extensive user training `}{`&`}{` support.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "right"
            }}><strong parentName="td">{`➥`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Software `}{`&`}{` hardware products evaluation, testing `}{`&`}{` troubleshooting.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <h2 style={{
        color: 'Green'
      }}>Assembly 6510 (8-bit MOS Technology microprocessor)</h2><hr />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "center"
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "center"
            }}><strong parentName="td">{`Floppy Diskette protection system`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Managed to break/bypass the Commodore 64 read-only floppy diskette protection system.`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <a href="#Top" style={{
        color: 'rgb(255,255,0)'
      }}><font color="#E67E22">≡ Top</font></a>
    </div>
    <br />
    <br />
    <br />
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#7DCEA0'
    }}>
  <h1 style={{
        color: 'Blue'
      }}>OPERATING SYSTEMS</h1>
    </div>
    <hr />
    <br />
    <div style={{
      padding: '30px 60px',
      backgroundColor: '#E9F7EF'
    }}>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}></th>
            <th parentName="tr" {...{
              "align": "left"
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`UNIX:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{` `}{` `}{` `}{` `}<b><u>{`- HP-UX (HP-9000/715):`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{` `}{` `}{` `}{` `}<b><u>{`- SUN Solaris:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{` `}{` `}{` `}{` `}<b><u>{`- AIX (RS6000):`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Teaching College Courses`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{` `}{` `}{` `}{` `}<b><u>{`- SCO-XENIX:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{` `}{` `}{` `}{` `}<b><u>{`- ALTOS-UNIX:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`LINUX:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`BANYAN VINES:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`MPE-XL (HP-3000/922, 927):`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`PRIMOS:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`MS Windows:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`NOVELL Netware:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development, Administration`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`MS-DOS:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use, Software Development`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`PC-DOS:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><b><u>{`OS/2:`}</u></b></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use`}</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <br />
      <a href="#Top" style={{
        color: 'rgb(255,255,0)'
      }}><font color="#E67E22">≡ Top</font></a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      